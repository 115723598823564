import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import { NoteAddRounded } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { generateQRPassesForEvent } from "../../../../../../../../../../services/wEvents/eventGuests";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import logo from '../../../../../../../../../../assets/WowslyLogo.png';
import { eventDetails } from "../../../../../../../../../../redux/slices/eventDataSlice";
import { EventSettingsContext } from "../../../../index";
import QRTicketsPayment from "../QRTicketsPayment";
import { parseDecimalNumber } from "../../../../../../../../../../utils/functions/getFormattedNumber";
import { createOrder } from "../../../../../../../../../../services/Payment/paymentGateway";
import { loadScript } from "../../../../../../../../../../utils/functions/loadScript";
import { ticketCurrencies } from "../../../../../../../../../../utils/constants/ticketCurrency";
import { userDetails } from "../../../../../../../../../../redux/slices/userDataSlice";
import { razorPayModalConfig } from "../../../../../../../../../../utils/constants/razorPayModalConfig";
import getGeneratedOrSendTicketCharges from "../../../../../../../../../../utils/functions/getGeneratedOrSendTicketCharges";
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';

const GenerateQRTicketsDialog = ({ ticket }) => {
    const theme = useTheme();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [loader, setLoader] = useState({
        qrPasses: false,
        qrCodeValues: false
    })
    const [paymentDone, setPayment] = useState(false);

    const eventData = useSelector(eventDetails);
    const userData = useSelector(userDetails);

    const isSmallDevices = useMediaQuery("(max-width:400px)");

    const { tickets, setTickets } = useContext(EventSettingsContext);
    const razorPayCurrency = ticketCurrencies.find((t) => t.value === "rupees")?.currency;

    const GST_PERCENT = process.env.REACT_APP_GST_PERCENT_FOR_SEND_MESSAGE;

    const handleCloseDialog = () => {
        setLoader({ ...loader, qrCodeValues: false, qrPasses: false });
        reset();
        setPayment(false);
        setIsDialogOpen(false);
    };

    const schema = yup.object({
        passQuantity: yup
            .number()
            .label("Pass quantity")
            .typeError("This field is required")
            .required()
            .integer("This field is required")
            .min(1)
            // .max(500)
            .max(ticket?.quantity - ticket?.sold_out)
    });

    const {
        handleSubmit,
        register,
        reset,
        setError,
        formState: { errors },
        watch
    } = useForm({
        defaultValues: {
            passQuantity: 0
        },
        resolver: yupResolver(schema)
    });
    const passQuantity = watch("passQuantity");
    let subTotal = getGeneratedOrSendTicketCharges(passQuantity) * passQuantity;
    // Calculate facility charge based on is_included: 1, taking 1 INR charge for each included facility
    const facilityCharge = ticket.facilities.reduce((acc, facility) => {
        return acc + (facility.is_included === 1 ? 1 : 0);
    }, 0);
    const totalFacilityCharge = facilityCharge * passQuantity;
    
    subTotal += totalFacilityCharge;
    let gstCharge = (GST_PERCENT / 100) * subTotal;
    gstCharge = parseDecimalNumber(gstCharge, 2);
    const totalAmountToPay = subTotal + gstCharge;

    const generateQRPasses = (downloadDataType) => {
        const apiPayload = {
            'passQuantity': passQuantity,
            'downloadDataType': downloadDataType
        }
        generateQRPassesForEvent({
            eventId: eventData?.id,
            ticketId: ticket.id,
            payload: apiPayload
        }).then(res => {
            if (downloadDataType === 'qrCodeValues') {
                const fileBlob = new Blob([res.data], { type: 'text/csv' });
                const downloadLink = document.createElement("a");
                document.body.appendChild(downloadLink);
                const fileUrl = URL.createObjectURL(fileBlob);

                // Create a link to download the file
                downloadLink.href = fileUrl;
                downloadLink.download = `${eventData.title}-${ticket?.id}-QR Code Values.csv`;
                // Add the link to the DOM and click it
                downloadLink.click();

                // Clean up the URL object
                URL.revokeObjectURL(fileUrl);
            } else {
                setPayment(true);
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${eventData?.title.replaceAll(" ", "_").toLowerCase()}_passes.zip`);
                document.body.appendChild(link);
                link.click();
            }
            const toastMessage = downloadDataType === 'qrCodeValues' ? "CSV for QR Code values generated!!" :
                ((userData?.mobile === "7574092395" || userData?.mobile === "9998227412" || userData?.mobile === "9428362224") ?
                    "QR passes are generated!!" :
                    "Payment successful, QR passes are generated!!");
            toast.success(toastMessage, {
                position: "top-right",
                theme: "dark"
            });

            const ticketIdx = tickets.findIndex(t => t.id === ticket?.id);
            if (ticketIdx !== -1) {
                const updatedTickets = [...tickets];
                updatedTickets[ticketIdx].sold_out += Number(passQuantity);
                setTickets(updatedTickets);
            }
            reset();
            handleCloseDialog();
        }).catch(err => {
            console.log(err);
            if (err.response?.data) {
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
            } else {
                toast.error('Something went wrong. Please try again!!', {
                    position: "top-right",
                    theme: "dark"
                });
            }
        });
    }

    const onSubmit = async (values, event) => {
        // if (values['passQuantity'] > 500) {
        //     setError('passQuantity', 'you can generate only 500 passes at a time above available.');
        //     return;
        // }
        // to know what we are downloading (Qr Passes or Qr Code Values)
        const downloadDataType = event.nativeEvent.submitter.id;
        setLoader({...loader, [downloadDataType]: true})
        
        // downloading Qr Code Values will not require payment 
        if (downloadDataType === 'qrCodeValues' || (userData?.mobile === "7574092395" || userData?.mobile === "9998227412" || userData?.mobile === "9428362224")) {
            generateQRPasses(event.nativeEvent.submitter.id);
        } else {
            const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
            if (!res) {
                alert('Failed to load Razorpay payment gateway. Please try again later.');
                return;
            }

            const razorPayPayload = {
                payment_amount: totalAmountToPay * 100, // For rupees send in paisa
                amount_currency: razorPayCurrency,
                receipt: 'Wowsly_Generate_Passes',
            }
            createOrder(razorPayPayload).then((result) => {
                setLoader({...loader, [downloadDataType]: false})
                console.log(result);
                const order_id = result.data?.id;
                const options = {
                    key: process.env.REACT_APP_RAZOR_PAY_LIVE_KEY,
                    amount: totalAmountToPay * 100, // Amount in paise
                    currency: razorPayCurrency,
                    name: 'Generate Pass Payment',
                    description: 'Payment for generating QR passes',
                    notes: {
                        'task': 'generate_or_send_qr_passes',
                        'user_id': userData?.id,
                        'ticket_id': ticket.id,
                        'currency': razorPayCurrency,
                        'gst_percent': process.env.REACT_APP_GST_PERCENT_FOR_SEND_MESSAGE,
                        'gst_charges': gstCharge,
                        'total_passes': passQuantity,
                        'type': "Generate"
                    },
                    image: logo,
                    order_id: order_id,
                    handler: function (response) {
                        // Callback function triggered on successful payment
                        console.log("Done", response);
                        if ('razorpay_payment_id' in response
                            && 'razorpay_order_id' in response
                            && 'razorpay_signature' in response
                        ) {
                            generateQRPasses(event.nativeEvent.submitter.id);
                        } else {
                            setLoader({...loader, [downloadDataType]: false})
                            toast.error("Payment failed due to some issues, please try again later!!", {
                                position: "top-right",
                                theme: "dark"
                            });
                            return;
                        }
                    },
                    "modal": {
                        "ondismiss": function () {
                            setLoader({...loader, [downloadDataType]: false})
                        }
                    },
                    prefill: {
                        name: userData?.full_name,
                        email: userData?.email ?? null,
                        contact: userData?.mobile,
                    },
                    theme: {
                        color: theme.palette.primaryColor,
                    },
                    config: razorPayModalConfig.paymentMethodsConfiguration,
                };
                const razorpay = new window.Razorpay(options);
                razorpay.open();
                razorpay.on("payment.failed", function (response) {
                    setLoader({...loader, [downloadDataType]: false})
                    console.log(response);
                    toast.error(response.error.description, {
                        position: "top-right",
                        theme: "dark"
                    });
                });
            }).catch((err) => {
                console.log(err);
                setLoader({...loader, [downloadDataType]: false})
                toast.error(err.response.data.error, {
                    position: "top-right",
                    theme: "dark"
                });
            });
        }
    };

    return (
        <>
            <Button
                variant="outlined"
                color="warning"
                size="small"
                disabled={(ticket?.quantity - ticket?.sold_out) === 0}
                startIcon={<NoteAddRounded />}
                onClick={() => setIsDialogOpen(true)}
            >
                Generate QR Passes
            </Button>
            <Dialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
            >
                <DialogTitle sx={{ p: "0.5rem 1rem" }}>
                    <IconButton
                        onClick={() => handleCloseDialog()}
                        sx={{ position: "absolute", right: "0.2rem", top: "0.2rem" }}
                    >
                        <CloseIcon fontSize="medium" />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: "1.5rem", sm: "1.5rem" } }}>
                        Generate QR Passes
                    </Typography>
                </DialogTitle>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <DialogContent dividers={true}>
                        {(ticket?.quantity - ticket?.sold_out) === 0 ? (
                            <Typography>
                                You have generated all the available passes.
                            </Typography>
                        ) : (
                            <Box pb={2}>
                                <Typography>
                                    Please select the number of passes you want to generate
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    fontWeight: "500",
                                                    fontSize: "15px",
                                                    display: "inline-block"
                                                }}>
                                                Available:
                                            </Typography>
                                            <Typography sx={{ ml: 1, color: "#6F738B", display: "inline-block" }}>
                                                {ticket?.quantity - ticket?.sold_out}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                color="warning"
                                                label="Pass Quantity"
                                                inputProps={{ type: "number" }}
                                                error={!!errors?.passQuantity}
                                                helperText={errors?.passQuantity?.message}
                                                {...register("passQuantity")}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        )}
                    </DialogContent>
                    {(ticket?.quantity - ticket?.sold_out) !== 0 ? (
                        <DialogActions sx={{ display: 'flex', justifyContent: 'center', my: 0.5 }}>
                            <LoadingButton
                                type="submit"
                                id="qrCodeValues"
                                variant="contained"
                                color="warning"
                                disabled={loader.qrCodeValues || (ticket?.quantity - ticket?.sold_out) === 0}
                                loading={loader.qrCodeValues ? true : false}
                                loadingPosition={loader.qrCodeValues ? "start" : null}
                                startIcon={loader.qrCodeValues ? <SaveIcon /> : null}
                                sx={{ fontSize: isSmallDevices ? "0.805rem" : "auto", lineHeight: isSmallDevices ? "1.25" : "auto" }}
                            >
                                Download CSV
                            </LoadingButton>
                            {
                                (userData?.mobile === "7574092395" || userData?.mobile === "9998227412" || userData?.mobile === "9428362224") ? 
                                    <LoadingButton
                                        type="submit"
                                        id="qrPasses"
                                        variant="contained"
                                        color="warning"
                                        disabled={loader.qrPasses || (ticket?.quantity - ticket?.sold_out) === 0}
                                        loading={loader.qrPasses ? true : false}
                                        loadingPosition={loader.qrPasses ? "start" : null}
                                        startIcon={loader.qrPasses ? <SaveIcon /> : null}
                                        sx={{ fontSize: isSmallDevices ? "0.805rem" : "auto", lineHeight: isSmallDevices ? "1.25" : "auto" }}
                                    >
                                        Generate & Download
                                    </LoadingButton> : 
                                        <QRTicketsPayment
                                            type="Download"
                                            loading={loader.qrPasses}
                                            paymentDone={paymentDone}
                                        />
                            }
                        </DialogActions>
                    ) : null}
                </form>
            </Dialog>
        </>
    );
};

export default GenerateQRTicketsDialog;