import React, { useEffect, useMemo, useState } from "react";
import { InfoRounded, SendRounded } from "@mui/icons-material";
import Button from "@mui/material/Button";
import {
    capitalize,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    Grid,
    IconButton, InputLabel, MenuItem, Select,
    Stack,
    TextField,
    useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import "../../../../../../../../event-details.scss";
import { sendQRPassesForEvent } from "../../../../../../../../../../services/wEvents/eventGuests";
import { useSelector } from "react-redux";
import { eventDetails } from "../../../../../../../../../../redux/slices/eventDataSlice";
import sendTicketGuestsWithFacilityCsv from "../../../../../../../../../../assets/csvfile/Send Passes Format Wowsly With Facility.csv";
import sendTicketGuestsWithoutFacilityCsv from "../../../../../../../../../../assets/csvfile/Send Passes Format Wowsly Without Facility.csv";
import { getInvitationMessageTemplates } from "../../../../../../../../../../services/wEvents/ticketTemplates";
import FormControl from "@mui/material/FormControl";
import _ from "lodash";
import Papa from "papaparse";
import QRTicketsPayment from "../QRTicketsPayment";
import { loadScript } from "../../../../../../../../../../utils/functions/loadScript";
import { parseDecimalNumber } from "../../../../../../../../../../utils/functions/getFormattedNumber";
import { ticketCurrencies } from "../../../../../../../../../../utils/constants/ticketCurrency";
import { createOrder } from "../../../../../../../../../../services/Payment/paymentGateway";
import { userDetails } from "../../../../../../../../../../redux/slices/userDataSlice";
import logo from '../../../../../../../../../../assets/WowslyLogo.png';
import { razorPayModalConfig } from "../../../../../../../../../../utils/constants/razorPayModalConfig";
import getGeneratedOrSendTicketCharges from "../../../../../../../../../../utils/functions/getGeneratedOrSendTicketCharges";
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';

const SendTicketsDialog = ({ ticket }) => {

    const theme = useTheme();
    const eventData = useSelector(eventDetails);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [messageTemplates, setMessageTemplates] = useState([]);
    const razorPayCurrency = ticketCurrencies.find((t) => t.value === "rupees")?.currency;
    const userData = useSelector(userDetails);
    const [paymentDone, setPayment] = useState(false);
    const [totalGuests, setTotalGuests] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [includedFacilities, setIncludedFacilities] = useState(0);
    const [facilities, setFacilities] = useState(0);

    const GST_PERCENT = process.env.REACT_APP_GST_PERCENT_FOR_SEND_MESSAGE;

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        reset();
        setFile(null);
    };

    const schema = yup.object({});

    const availablePasses = useMemo(() => {
        return ticket?.quantity - ticket?.sold_out;
    }, [ticket]);

    const {
        handleSubmit,
        register,
        setValue,
        getValues,
        control,
        watch,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            guestList: null,
            organiserName: eventData?.owner.full_name || "",
            eventName: eventData?.title,
            date: eventData?.start_date,
            time: eventData?.start_time,
            venue: eventData?.address,
        },
        resolver: yupResolver(schema),
    });

    const templateId = watch("templateId");

    // calculates sum of Guests allowed per ticket and no. of rows in csv file
    const calculateTotals = (data) => {
        let totalGuest = 0;
        let totalRows = 0;
        let includedFacilitiesCount = 0;
        let facilitiesCount = 0;
        if (ticket && ticket.facilities) {
            for (let i = 0; i < ticket.facilities.length; i++) {
                if (ticket.facilities[i].is_included === 1) {
                    includedFacilitiesCount += 1;
                }
            }
        }

        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                // check if contact exists and not null
                if (
                    "CountryCode" in data[i] &&
                    "MobileNumber" in data[i] &&
                    data[i]["MobileNumber"] !== "" &&
                    data[i]["CountryCode"] !== ""
                ) {
                    totalRows += 1;
                    const value = parseInt(data[i]["GuestsAllowedPerTicket"]);
                    if (!isNaN(value)) {
                        // if value is specified in csv file take it
                        totalGuest += value;
                    } else if (data[i]["GuestsAllowedPerTicket"] === "") {
                        // if value is not specified in csv file then consider 1
                        totalGuest += 1;
                    }
                }
                if (data[i]["Facilities"]){
                    totalGuest = totalRows
                    let facilitiesFromData;
                    try {
                        // Replace curly quotes with straight quotes
                        let facilitiesString = data[i]["Facilities"]
                            .replace(/“/g, '"')
                            .replace(/”/g, '"')
                            .replace(/‘/g, '"')
                            .replace(/’/g, '"');
                        facilitiesFromData = JSON.parse(facilitiesString);
                    } catch (e) {
                        console.error(
                            "Failed to parse facilities from data:",
                            data[i]["Facilities"]
                        );
                        continue;
                    }
                    if (ticket && ticket.facilities) {
                        for (let j = 0; j < ticket.facilities.length; j++) {
                            let facilityNameFromTicket = ticket.facilities[j].name
                                .trim()
                                .toLowerCase();
                            // Match with facilities from data
                            for (let key in facilitiesFromData) {
                                let facilityNameFromData = key.trim().toLowerCase();
                                if (
                                    facilityNameFromTicket ===
                                        facilityNameFromData &&
                                    facilitiesFromData[key].trim().toLowerCase() ===
                                        "yes" &&
                                    ticket.facilities[j].is_included !== 1
                                ) {
                                    facilitiesCount += 1;
                                }
                            }
                        }
                    }
                }
                
            }
        }
        setIncludedFacilities(includedFacilitiesCount * totalRows);
        setFacilities(facilitiesCount);
        setTotalGuests(totalGuest);
        setTotalRows(totalRows);
    };


    const parseCSVFile = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => { // onload event is triggered once file contents are read
            const csvText = e.target.result;
            const { data } = Papa.parse(csvText, { header: true }); // parse csv text to array of objects
            calculateTotals(data);
        };
        reader.readAsText(file); // reads content of file as text
    };

    const handleFileChange = (e) => {
        if (e.type !== "text/csv") {
            toast.error("Only CSV file allowed", {
                position: "top-right",
                theme: "dark"
            });
            return;
        }
        if (e) {
            setFile(e);
            setValue("guestList", e);
            parseCSVFile(e);
        }
    };
    let subTotal = getGeneratedOrSendTicketCharges(totalGuests) * totalGuests;
    let facilitiesCharges = (includedFacilities + facilities) * 1; // apply 1 RS charge for each facilities 
    let emailAndWhatsappCharges = 0.99 * totalRows;
    subTotal += emailAndWhatsappCharges; // apply whatsapp and email charges for each row
    subTotal += facilitiesCharges; // applly facilitiesCharges
    let gstCharge = (GST_PERCENT / 100) * subTotal;
    gstCharge = parseDecimalNumber(gstCharge, 2);
    const totalAmountToPay = subTotal + gstCharge;

    const sendQRPasses = (values) => {
        const formData = new FormData();
        formData.append("guest_list", values.guestList);
        formData.append("organiser_name", values.organiserName);
        formData.append('template_id', values.templateId);
        const templateData = {};
        for (const field of templateFields) {
            templateData[field[0]] = values[field[0]];
        }
        formData.append('template_data', JSON.stringify(templateData));
        sendQRPassesForEvent({
            eventId: eventData?.id,
            ticketId: ticket?.id,
            payload: formData
        }).then(res => {
            setPayment(true);
            setLoading(false);
            // if (res.data.success) {
            //     const ticketIdx = tickets.findIndex(t => t.id === ticket?.id);
            //     if (ticketIdx !== -1) {
            //         const updatedTickets = [...tickets];
            //         updatedTickets[ticketIdx].sold_out += res.data.total_sent;
            //         setTickets(updatedTickets);
            //     }
            // }
            toast.success(`QR passes will be sent to users`, {
                position: "top-right",
                theme: "dark"
            });
            reset();
            handleCloseDialog();
        }).catch(err => {
            setLoading(false);
            console.log(err);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        });
    }

    const onSubmit = async (values) => {
        setLoading(true);
        if (!values.guestList) {
            setLoading(false);
            toast.error("Something went wrong!!", {
                position: "top-right",
                theme: "dark"
            });
            return;
        } else if (userData?.mobile === "7574092395" || userData?.mobile === "9998227412" || userData?.mobile === "9428362224") {
            sendQRPasses(values);
        } else {
            const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
            if (!res) {
                alert('Failed to load Razorpay payment gateway. Please try again later.');
                return;
            }

            const razorPayPayload = {
                payment_amount: totalAmountToPay * 100, // For rupees send in paisa
                amount_currency: razorPayCurrency,
                receipt: 'Wowsly_Send_Tickets',
            }

            createOrder(razorPayPayload).then((result) => {
                console.log(result);
                const order_id = result.data?.id;
                const options = {
                    key: process.env.REACT_APP_RAZOR_PAY_LIVE_KEY,
                    amount: totalAmountToPay * 100, // Amount in paise
                    currency: razorPayCurrency,
                    name: 'Send Pass Payment',
                    description: 'Payment for sending QR passes',
                    notes: {
                        'task': 'generate_or_send_qr_passes',
                        'user_id': userData?.id,
                        'ticket_id': ticket.id,
                        'currency': razorPayCurrency,
                        'gst_percent': process.env.REACT_APP_GST_PERCENT_FOR_SEND_MESSAGE,
                        'gst_charges': gstCharge,
                        'total_passes': totalGuests,
                        'type': "Send",
                        'whatsapp_and_email_charges': emailAndWhatsappCharges
                    },
                    image: logo,
                    order_id: order_id,
                    handler: function (response) {
                        // Callback function triggered on successful payment
                        console.log("Done", response);
                        if ('razorpay_payment_id' in response
                            && 'razorpay_order_id' in response
                            && 'razorpay_signature' in response
                        ) {
                            sendQRPasses(values);
                        } else {
                            setLoading(false);
                            toast.error("Payment failed due to some issues, please try again later!!", {
                                position: "top-right",
                                theme: "dark"
                            });
                            return;
                        }
                    },
                    "modal": {
                        "ondismiss": function () {
                            setLoading(false);
                        }
                    },
                    prefill: {
                        name: userData?.full_name,
                        email: userData?.email ?? null,
                        contact: userData?.mobile,
                    },
                    theme: {
                        color: theme.palette.primaryColor,
                    },
                    config: razorPayModalConfig.paymentMethodsConfiguration,
                };
                const razorpay = new window.Razorpay(options);
                razorpay.open();
                razorpay.on("payment.failed", function (response) {
                    setLoading(false);
                    console.log(response);
                    toast.error(response.error.description, {
                        position: "top-right",
                        theme: "dark"
                    });
                });
            }).catch((err) => {
                console.log(err);
                setLoading(false);
                toast.error(err.response.data.error, {
                    position: "top-right",
                    theme: "dark"
                });
            });
        }
    };

    const templateFields = useMemo(() => {
        const fields = JSON.parse(messageTemplates.find(t => t.id === templateId)?.fields || "{}");
        if (!(_.isEmpty(fields))) {
            return Object.entries(fields).sort(([, a], [, b]) => a.order - b.order);
        }
        return [];
    }, [templateId, messageTemplates]);

    const messagePreviewText = useMemo(() => {
        const template = messageTemplates.find(t => t.id === templateId);
        if (!template) return '';
        const values = getValues();
        return template.template_string.replace(/\{\{(.+?)\}\}/g, (matching, value) => {
            return values[value.trim()] ? values[value.trim()] : matching;
        });
        return template.template_string;
    }, [templateId, watch()]);

    useEffect(() => {
        getInvitationMessageTemplates(ticket.event_id)
            .then(res => {
                if (res.data.success) {
                    setMessageTemplates(res.data.data);
                    setValue("templateId", 1);
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(Object.values(err.response.data)[0][0], {
                    position: "top-right",
                    theme: "dark"
                });
            });
    }, []);

    return (
        <>
            <Button
                variant="outlined"
                color="warning"
                size="small"
                startIcon={<SendRounded />}
                onClick={() => setIsDialogOpen(true)}
            >
                Send Tickets
            </Button>
            <Dialog
                fullWidth
                open={isDialogOpen}
                onClose={handleCloseDialog}
                maxWidth="sm"
            >
                <DialogTitle sx={{ p: "0.5rem 1rem" }}>
                    <IconButton
                        onClick={() => handleCloseDialog()}
                        sx={{ position: "absolute", right: "0.2rem", top: "0.2rem" }}
                    >
                        <CloseIcon fontSize="medium" />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: "1.5rem", sm: "1.5rem" } }}>
                        Send Tickets
                    </Typography>
                </DialogTitle>
                <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <DialogContent dividers={true}>
                        <Box pb={2}>
                            <Typography>
                                Please select the csv of guests
                            </Typography>
                            {/* if there are facilities only 1 guest allowed per ticket so different csv file formats */}
                            <Button fullWidth href={ticket.facilities.length > 0 ? sendTicketGuestsWithFacilityCsv : sendTicketGuestsWithoutFacilityCsv} download="Guests List.csv" color="warning"
                                variant="outlined"
                                sx={{ color: "#FF8359", my: 2, p: 2 }}>
                                Download Sample Csv File
                            </Button>
                            <Divider />
                            <Box sx={{ mt: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography
                                            sx={{
                                                fontWeight: "500",
                                                fontSize: "15px",
                                                display: "inline-block"
                                            }}>
                                            Available:
                                        </Typography>
                                        <Typography sx={{ ml: 1, color: "#6F738B", display: "inline-block" }}>
                                            {availablePasses}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth color="warning">
                                            <InputLabel>Select Message Template</InputLabel>
                                            <Controller
                                                name="templateId"
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        fullWidth
                                                        label="Select Message Tempalte"
                                                        value={value}
                                                        onChange={onChange}
                                                    >
                                                        {messageTemplates.map((o, idx) => (
                                                            <MenuItem key={`template_${idx}`} value={o.id}>
                                                                {capitalize(o.dotpe_template_id.replaceAll("_", " "))}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {templateFields.map((fieldName) => (
                                        <React.Fragment key={fieldName[0]}>
                                            {fieldName[1].isEditable ? (
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        color="warning"
                                                        label={capitalize(fieldName[0])}
                                                        error={errors?.[fieldName] ? true : false}
                                                        helperText={
                                                            errors[fieldName[0]] ? errors[fieldName[0]].message : null
                                                        }
                                                        {...register(fieldName[0])}
                                                    />
                                                </Grid>
                                            ) : null}
                                        </React.Fragment>
                                    ))}
                                    <Grid item xs={12}>
                                        <Divider sx={{ my: 2 }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline
                                            fullWidth
                                            color="warning"
                                            label="Message Preview"
                                            value={messagePreviewText}
                                        />
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}>
                                        <FileUploader
                                            name="guestList"
                                            handleChange={handleFileChange}
                                            types={["csv"]}
                                            classes="group-upload-csv-drag-drop-area"
                                            required={!file}
                                            maxSize={5}
                                            children={
                                                <Stack
                                                    spacing={1}
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    sx={{
                                                        height: "100%",
                                                        borderRadius: "0.5rem",
                                                        border: `3.5px dashed ${theme.palette.primaryColor}`,
                                                        ":hover": {
                                                            cursor: "pointer"
                                                        }
                                                    }}
                                                >
                                                    <DriveFolderUploadIcon
                                                        sx={{ fontSize: "2rem", color: theme.palette.primaryColor }}
                                                    />
                                                    <Stack
                                                        spacing={1}
                                                        direction="row"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        {file ? <CheckCircleIcon color="success" /> : null}
                                                        <Typography sx={{ color: "#6F738B", fontSize: "0.9rem" }}>
                                                            {file ? "Uploaded Successfully" : "Drop or Upload Guest List CSV File"}
                                                        </Typography>
                                                    </Stack>

                                                    {file ? (
                                                        <Typography sx={{ color: "#6F738B", fontSize: "0.9rem" }}>
                                                            File name: {file.name}
                                                        </Typography>
                                                    ) : null}
                                                </Stack>
                                            }
                                            onSizeError={() => {
                                                toast.error("Maximum upload size for file is 5MB", {
                                                    position: "top-right",
                                                    theme: "dark"
                                                });
                                            }}
                                            dropMessageStyle={{ backgroundColor: "red" }}
                                        />
                                        <Box
                                            display='flex'
                                            justifyContent='flex-start'
                                            alignItems='flex-start'
                                            gap={0.5}
                                            mt={0.5}
                                        >
                                            <InfoRounded fontSize='0.75rem'></InfoRounded>
                                            <Typography variant='caption'>
                                                Please keep in mind that only available numbers of tickets will be sent. Rest entries in CSV will be ignored.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ mr: 1, my: 0.5 }}>
                        {
                            (userData?.mobile === "7574092395" || userData?.mobile === "9998227412" || userData?.mobile === "9428362224") ?
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="warning"
                                    loading={loading}
                                    disabled={loading || availablePasses === 0}
                                    loadingPosition={loading ? "start" : null}
                                    startIcon={loading ? <SaveIcon /> : null}
                                >
                                    Generate & Send
                                </LoadingButton> :
                                <Box>
                                    <QRTicketsPayment
                                        loading={loading}
                                        paymentDone={paymentDone}
                                        type="Send"
                                    />
                                </Box>
                        }
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default SendTicketsDialog;