import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, Collapse, DialogActions, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, List, ListItem, Radio, RadioGroup, Skeleton, Stack, TextField, Typography } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GuestRegistrationContext } from "../..";
import { eventDetails } from "../../../../../../../../redux/slices/eventDataSlice";
import { getEventTickets, submitSelectedTicket } from '../../../../../../../../services/wEvents/eventDetails';
import { ticketCurrencies } from '../../../../../../../../utils/constants/ticketCurrency';
import { manageTicketSelectPayload } from '../../../../../../../../utils/functions/managePayload';
import TicketDetailsDialog from "./TicketDetailsDialog";
import TicketSelectionCard from "./TicketSelectionCard";
import { EventDetailsContext } from '../../../../../..';
import _ from 'lodash';
import { parseDecimalNumber } from '../../../../../../../../utils/functions/getFormattedNumber';

const SelectEventTicket = () => {
    const { commonEventTitle } = useParams();
    const eventData = useSelector(eventDetails);
    const eventDetailsObj = useSelector(eventDetails);
    const { handleSlideChange, paymentDetails, rForm, tickets, setTickets, ticketsCount, setTicketCount,
        selectedTicket, setSelectedTicket, registrationData,
        setRegistrationData, setPaymentDetails } = useContext(GuestRegistrationContext);
    const { setTabValue } = useContext(EventDetailsContext);

    const [loader, setLoader] = useState(false);
    const [viewTicket, setViewTicket] = useState(null);
    const [viewModal, setModal] = useState(false);
    const [defaultSelectedTicket, setDefaultSelectedTicket] = useState(registrationData?.selected_ticket);
    const { handleSubmit, control, watch, getValues, setValue,
        formState: { errors } } = useForm();

    const max_value = selectedTicket ? ((selectedTicket.max_ticket_limit !== null &&
        (selectedTicket?.quantity - selectedTicket?.sold_out) < selectedTicket.max_ticket_limit) ?
        (selectedTicket?.quantity - selectedTicket?.sold_out) : selectedTicket.max_ticket_limit) :
        null
    const form_selected_ticket = watch('selected_ticket');
    const [isWhatsappChecked, setIsWhatsappChecked] = useState(1);
    const activeUserId = (!(eventDetailsObj.is_paid && selectedTicket?.purchase_price > 0)) ? (
        eventData?.current_user_role === 'manager' ?
            eventData?.guest_id : (
                eventData?.current_user_role === 'owner' ? eventData?.event_owner_id : null)) :
        null;
    const eventTicketType = eventDetailsObj?.is_paid ? 'paid' : 'free';
    const [facilitiesSelected, setFacilitiesSelected] = useState(registrationData?.facility_status ?? []);
    useEffect(() => {
        // when navigating back to select event ticket tab from guest ticket payment tab show facilities which were already selected
        if (Number(registrationData?.selected_ticket) !== selectedTicket?.id) {
            if (selectedTicket?.facilities) {
                // by default all facilities will be deselected
                const facilitiesArray = selectedTicket.facilities.map(facility => ({
                    id: facility.id,
                    name: facility.name,
                    scan_quantity: facility.scan_quantity || null,
                    selected: facility.is_included ? true : false,
                }));
                setFacilitiesSelected(facilitiesArray);
            }
        } else {
            setFacilitiesSelected(registrationData?.facility_status ?? []);
        }
    }, [registrationData, selectedTicket]);

    const whatsAppCheck = () => {
        setIsWhatsappChecked(Number(!isWhatsappChecked));
    }

    useEffect(() => {
        getEventTickets({ eventId: eventData?.id, includeHiddenTickets: 0 }).then((res) => {
            console.log("event tickets: ", res.data.data)
            setTickets(res.data.data);
            if (!registrationData?.selected_ticket) {
                setDefaultSelectedTicket(res.data.data?.[0].id);
                setValue("selected_ticket", res.data.data?.[0].id);
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);


    useEffect(() => {
        const ticket = tickets?.find((s) => s.id === Number(getValues('selected_ticket')));
        let selectedTicketDetails
        if (eventData.is_multiple_tickets) {
            selectedTicketDetails = (tickets ?? [])
                .filter(ticket => ticketsCount?.[ticket.id] > 0)
                .map(ticket => ({
                    ticket_id: ticket.id,
                    ticket_count: ticketsCount?.[ticket.id],
                    ticket_price: ticket.purchase_price,
                }));
            // Set the selected ticket and its details
            setSelectedTicket({
                ...ticket,
                selectedTicketDetails // Attach the selected ticket details to the selected ticket
            });
        } 

    }, [form_selected_ticket, ticketsCount]);

    useEffect(() => {
        const ticket = tickets?.find((s) => s.id === Number(getValues('selected_ticket')));
        let selectedTicketDetails
        if(!eventData?.is_multiple_tickets) {
            selectedTicketDetails = (tickets ?? [])
                .filter(ticket => ticketsCount?.[ticket.id] > 0 && ticket.id === Number(getValues('selected_ticket')))
                .map(ticket => ({
                    ticket_id: ticket.id,
                    ticket_count: ticketsCount?.[ticket.id],
                    ticket_price: ticket.purchase_price,
                }));
        }
        // Set the selected ticket and its details
        setSelectedTicket({
            ...ticket,
            selectedTicketDetails // Attach the selected ticket details to the selected ticket
        });
    }, [form_selected_ticket]);

    const handleTicketViewClick = (t) => {
        setModal(true);
        setViewTicket(t);
    }

    const handleFacilitiesSelected = (selectedFacility) => (event) => {
        // reverse checkbox selection (if checked then unchecked and vice versa) keeping other facilities unchanged
        setFacilitiesSelected((prevFacilities) =>
            prevFacilities.map((facility) =>
                facility.id === selectedFacility.id ? { ...facility, selected: event.target.checked } : { ...facility }
            ))
    };

    // if there are any paid facilities, show next button and navigate to payment gateway
    const isAnyFacilitySelectedWithPrice = facilitiesSelected.some(selectedFacility => {
        if (selectedFacility.selected) {
            const matchingFacility = selectedTicket.facilities.find(ticketFacility => ticketFacility.id === selectedFacility.id);
            return matchingFacility && matchingFacility.price > 0;
        }
        return false;
    });
    const handleMinusTicketCount = (ticketId) => {
        setTicketCount((prev) => ({
            ...prev,
            [ticketId]: (prev[ticketId] || 0) > 0 ? (prev[ticketId] || 0) - 1 : 0 // Ensure count doesn't go below 0
        }));

    };

    const handlePlusTicketCount = (ticketId, max_value) => {
        setTicketCount((prev) => ({
            ...prev,
            [ticketId]: (prev[ticketId] || 0) < max_value ? (prev[ticketId] || 0) + 1 : max_value // Ensure count doesn't exceed max value
        }));

    };

    const mySubmit = (data) => {
        setLoader(true);
        setRegistrationData((prevData) => ({ ...prevData, ...data, facility_status: facilitiesSelected }));
        

        let totalFacilityPrice = 0;
        if (selectedTicket?.facilities.length > 0) {
            // if price of facility exists add it to final amount to be paid
            totalFacilityPrice = selectedTicket.facilities.reduce((total, facility) => {
                const selectedFacility = facilitiesSelected.find(fac => fac.id === facility.id);
                if (selectedFacility && selectedFacility.selected && facility.price) {
                    return total + facility.price;
                } else {
                    return total;
                }
            }, 0);
        }
        let selectedTicketDetails
        if (eventData.is_multiple_tickets) {

            selectedTicketDetails = (tickets ?? [])
                .filter(ticket => ticketsCount?.[ticket.id] > 0)
                .map(ticket => ({
                    ticket_id: ticket.id,
                    ticket_name: ticket.title,
                    ticket_count: ticketsCount?.[ticket.id],
                    ticket_price: ticket.purchase_price,
                }));
        } else {
            selectedTicketDetails = (tickets ?? [])
                .filter(ticket => ticketsCount?.[ticket.id] > 0 && ticket.id === Number(getValues('selected_ticket')))
                .map(ticket => ({
                    ticket_id: ticket.id,
                    ticket_name: ticket.title,
                    ticket_count: ticketsCount?.[ticket.id],
                    ticket_price: ticket.purchase_price,
                }));
        }

        // Calculate total amount for all selected tickets
        let totalAmountToPay = selectedTicketDetails.reduce((total, ticket) => {
            return total + (ticket.ticket_count * ticket.ticket_price);
        }, 0);
        
        // Prepare the payload with selected tickets and facility details
        const ticketPayload = {
            selected_tickets: selectedTicketDetails,  // The array of selected tickets
            total_amount_to_pay: totalAmountToPay + (totalFacilityPrice * ticketsCount[selectedTicket?.id] || 0),  // Total ticket and facility amount
            amount_currency: selectedTicket.currency,
            ticket_currency_symbol: ticketCurrencies.find((t) => t.value === selectedTicket.currency)?.symbol,
        };
        const payload = manageTicketSelectPayload(eventDetailsObj, ticketPayload, facilitiesSelected, paymentDetails?.guest_uuid, eventTicketType, activeUserId, isWhatsappChecked);
        setPaymentDetails((paymentDetails) => ({
            ...paymentDetails,
            ...ticketPayload,
            total_ticket_amount: ticketsCount * selectedTicket.purchase_price,
            total_facility_amount: totalFacilityPrice * ticketsCount[selectedTicket?.id],
        }));

        submitSelectedTicket(payload, eventDetailsObj?.id, { commonEvent: commonEventTitle ? true : false }).then((res) => {
            const response = res.data?.data;
            setPaymentDetails((paymentDetails) => ({
                ...paymentDetails,
                guest_ticket_id: response?.id,
                ticketId: response.ticket_id,
                ticketsCount: response.tickets_bought,
                ticket_price: response.per_ticket_price,
                total_amount_to_pay: totalAmountToPay + (totalFacilityPrice * ticketsCount[selectedTicket?.id] || 0),
                ticket_currency_symbol: ticketCurrencies.find((t) => t.value === response?.currency)?.symbol
            }));
            setLoader(false);
            // for owner and manager navigate to event page instead of showing successful registration for specific case
            if (activeUserId && !((eventDetailsObj?.is_paid && (selectedTicket?.purchase_price > 0 || isAnyFacilitySelectedWithPrice)) || (!eventDetailsObj?.is_paid && isAnyFacilitySelectedWithPrice))) {
                toast.success((isWhatsappChecked ? "You have registered for event, You'll receive your event ticket on your email and whatsapp!!" : "You have registered for event, You'll receive your event ticket on your email"), {
                    position: "top-right",
                    theme: "dark"
                });
                setTabValue(0);  // navigate to info tab
                return;
            }
            handleSlideChange(2);
            toast.success(((eventDetailsObj?.is_paid && (selectedTicket?.purchase_price > 0 || isAnyFacilitySelectedWithPrice)) || (!eventDetailsObj?.is_paid && isAnyFacilitySelectedWithPrice)) ? "Ticket selected" : (isWhatsappChecked ? "You have registered for event, You'll receive your event ticket on your email and whatsapp!!" : "You have registered for event, You'll receive your event ticket on your email"), {
                position: "top-right",
                theme: "dark"
            });
        }).catch((err) => {
            setLoader(false);
            console.log(err);
            toast.error(Object.values(err.response.data)[0][0], {
                position: "top-right",
                theme: "dark"
            });
        })

    }
    useEffect(()=>{
        if(!eventData.is_multiple_tickets && selectedTicket?.id && !ticketsCount[selectedTicket?.id]){
            setTicketCount(prev => ({
                ...prev,
                [selectedTicket?.id]: 1,
            }))
        }
    },[ticketsCount,selectedTicket?.id,setTicketCount,eventData])
   
    return (
        <>
            <form
                autoComplete="off"
                onSubmit={handleSubmit(mySubmit)}
            >
                <FormControl error={(errors['selected_ticket']) ? true : false}
                    sx={{ width: '100%' }}>
                    {rForm ?
                        <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>Select event ticket</Typography>
                        : <Skeleton sx={{ transform: 'scale(1,1)', width: '25%', my: 2 }} />
                    }

                    {eventData.is_multiple_tickets === 1 ? (
                        <Controller
                            control={control}
                            name="selected_ticket"
                            rules={{ required: true }}
                            defaultValue={`${defaultSelectedTicket}`}
                            render={({ field }) => (
                                <Grid container gap="1rem" alignItems="center">
                                    {(tickets ?? []).map((t, i) => {
                                        const max_value = t?.max_ticket_limit ?? 1; // Get max ticket limit from ticket data
                                        return (
                                            <TicketSelectionCard
                                                key={i}
                                                t={t}
                                                handleTicketViewClick={handleTicketViewClick}
                                                ticketsCount={ticketsCount[t.id] || 0} // Pass the count for this ticket
                                                max_value={max_value} // Max value for ticket count
                                                handlePlusTicketCount={handlePlusTicketCount} // Increment count for this ticket
                                                handleMinusTicketCount={handleMinusTicketCount} // Decrement count for this ticket
                                            />
                                        );
                                    })}
                                    {viewModal ? (
                                        <TicketDetailsDialog
                                            ticket={viewTicket}
                                            setModal={setModal}
                                        />
                                    ) : null}
                                </Grid>
                            )}
                        />
                    ) : (
                        <Controller
                            control={control}
                            name="selected_ticket"
                            rules={{ required: true }}
                            defaultValue={`${defaultSelectedTicket}`}
                            render={({ field }) => (
                                <RadioGroup {...field}>
                                    <Grid container gap="1rem" alignItems="center">
                                        {(tickets ?? Array.from(new Array(4)))?.map((t, i) => (
                                            <TicketSelectionCard key={i} t={t} handleTicketViewClick={handleTicketViewClick} />
                                        ))}
                                        {viewModal ? (
                                            <TicketDetailsDialog
                                                ticket={viewTicket}
                                                setModal={setModal}
                                            />
                                        ) : null}
                                    </Grid>
                                </RadioGroup>
                            )}
                        />
                    )
                    }

                    {errors['selected_ticket'] ?
                        <FormHelperText sx={{ mt: 1 }}>Please choose one option</FormHelperText> : null}
                </FormControl>
                <br /><br />
                {
                    eventData.is_multiple_tickets === 0 ? (
                        <Collapse in={selectedTicket?.facilities?.length > 0}>
                            {selectedTicket?.facilities?.filter(f => f.is_included).length > 0 ? (
                                <>
                                    <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                                        Included facilities:
                                    </Typography>
                                    <Stack direction='row'>
                                        <List
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                padding: 0,
                                                flexWrap: "wrap"
                                            }}
                                        >
                                            {selectedTicket?.facilities
                                                .filter(item => item.is_included)
                                                .map((item, index) => (
                                                    <ListItem
                                                        key={index}
                                                        sx={{
                                                            display: 'list-item',
                                                            listStyleType: 'disc',
                                                            paddingLeft: 0,
                                                            marginLeft: 2.5,
                                                            width: 'fit-content',
                                                        }}
                                                    >
                                                        <Typography>{_.capitalize(item.name)}</Typography>
                                                    </ListItem>
                                                ))}
                                        </List>
                                    </Stack>
                                </>
                            ) : null}
                            {selectedTicket?.facilities?.filter(f => !f.is_included).length > 0 ? (
                                <>
                                    <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                                        Additional Facilities:
                                    </Typography>
                                    <Stack direction='row' flexWrap="wrap">
                                        {selectedTicket?.facilities
                                            .filter(item => !item.is_included)
                                            .map((item, index) => {
                                                const facility =
                                                    facilitiesSelected.find(facility => facility.id === item.id) ||
                                                    {};

                                                const facilityPriceSymbol = item.currency
                                                    ? ticketCurrencies.find(f => f.value === item.currency)?.symbol
                                                    : '';
                                                const priceLabel = item.price ? (
                                                    <span>
                                                        (Price: {parseDecimalNumber(item.price, 3)}{' '}
                                                        <span
                                                            dangerouslySetInnerHTML={{ __html: facilityPriceSymbol }}
                                                        />
                                                        )
                                                    </span>
                                                ) : '';
                                                return (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={item.name}
                                                        control={
                                                            <Checkbox
                                                                color="warning"
                                                                checked={facility.selected || false}
                                                                onChange={handleFacilitiesSelected(item)}
                                                            />
                                                        }
                                                        label={
                                                            <Fragment>
                                                                {_.capitalize(item.name)} {priceLabel}
                                                            </Fragment>
                                                        }
                                                        labelPlacement="end"
                                                        sx={{ paddingRight: '12px' }}
                                                    />
                                                );
                                            })}
                                    </Stack>
                                </>
                            ) : null}
                        </Collapse>
                    ) : null
                }

                {
                    eventData.is_multiple_tickets === 0 ? (
                        tickets ? (
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                                    Number of tickets:
                                </Typography>
                                <Stack direction='row' spacing={1} alignItems='center'>
                                    <IconButton onClick={() => handleMinusTicketCount(selectedTicket?.id)}>
                                        <RemoveCircleOutlineIcon color='warning' />
                                    </IconButton>
                                    <TextField
                                        color="warning"
                                        sx={{ width: '5rem' }}
                                        inputProps={{
                                            type: 'number',
                                            min: 1,
                                            max: max_value
                                        }}
                                        value={ticketsCount[selectedTicket?.id] || 1} // Correctly access ticketsCount for the selected ticket
                                        onChange={(e) => {
                                            const value = Number(e.target.value);
                                            if (value <= max_value) {
                                                setTicketCount((prev) => ({
                                                    ...prev,
                                                    [selectedTicket?.id]: value, // Correctly update count for the selected ticket
                                                }));
                                            }
                                        }}
                                    />
                                    <IconButton onClick={() => handlePlusTicketCount(selectedTicket?.id, max_value)}>
                                        <AddCircleOutlineIcon color='warning' />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        ) : null
                    ) : null
                }

                {
                    // should whatsapp checkbox be visible check should be done after selected ticket is set 
                    ((eventDetailsObj?.is_paid && (selectedTicket ? (selectedTicket?.purchase_price > 0 || isAnyFacilitySelectedWithPrice) : true)) || (!eventDetailsObj?.is_paid && isAnyFacilitySelectedWithPrice)) ? <></>
                        : <Box sx={{ textAlign: 'right', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: { xs: "1rem" } }}>
                                Do you want ticket on WhatsApp?
                            </Typography>
                            <Checkbox sx={{ paddingRight: 0 }} checked={isWhatsappChecked ? true : false} color='warning' onChange={(e) => { whatsAppCheck() }} />
                        </Box>
                }
                <DialogActions sx={{ pr: 0 }}>
                    {
                        selectedTicket ?
                            <LoadingButton
                                type="submit"
                                color="warning"
                                variant="contained"
                                loading={loader ? true : false}
                                disabled={!ticketsCount || 
                                    Object.keys(ticketsCount).length === 0 || 
                                    Object.values(ticketsCount).every(count => count === 0)}
                                startIcon={loader ? <SaveIcon /> : null}
                                loadingPosition={loader ? "start" : null}
                            >
                                {loader ? 'Saving...' : ((eventDetailsObj?.is_paid && (selectedTicket?.purchase_price > 0 || isAnyFacilitySelectedWithPrice)) || (!eventDetailsObj?.is_paid && isAnyFacilitySelectedWithPrice)) ? 'Next' : 'Submit'}
                            </LoadingButton> :
                            <Skeleton width='15%' height='4rem' animation="wave" />
                    }
                </DialogActions>
            </form>
        </>
    )
};

export default SelectEventTicket;
